import React from 'react'
import { Box, Container, HStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import BackButton from '../../atoms/nav-controls/BackButton'
import PresentationButton from '../../atoms/nav-controls/PresentationButton'
import NavBar from '../../atoms/NavBar'
import { SmartInviteSelector } from './selectors/SmartInviteSelector'
import { VisibilitySelector } from './selectors/VisibilitySelector'
import TheatreHeader from '../../molecules/TheatreHeader'
import SharingOptionsCard from '../../molecules/SharingOptionsCard'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'

const SendToGeneral = () => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	const handleBack = () => navigate(-1) //handlers.root
	const handlePresent = () => navigate(handlers.public_root)
	const handleClickInviteList = () => redirectToNextIfWindow(handlers.invitation_list) // navigate(handlers.invitation_list)

	return (
		<>
			<NavBar>
				<HStack spacing={['4%', '1rem']}>
					<BackButton onClick={handleBack} />
				</HStack>
				{/* <HStack spacing={['4%', '1rem']} justifyContent="flex-end">
					<PresentationButton onClick={handlePresent} />
				</HStack> */}
			</NavBar>

			<Container maxW="container.lg" variant="main" px="1rem">
				<Box mb="2rem">
					<TheatreHeader
						title="Share with Friends"
						description="When your video is viewable, people can see and share your video."
					/>
				</Box>

				<VisibilitySelector event={event} mb="1rem" />

				<SharingOptionsCard role="creator" mb="1rem" />

				<SmartInviteSelector onClick={handleClickInviteList} mb="1rem" />
			</Container>
		</>
	)
}

export default SendToGeneral
