import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'

const ModalHideVideo = ({ onClose, isOpen, onHideVideo }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/modal-hide-video',
		ext: 'png',
	})

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Hide Your Video" boxSize="64px" />
						<Heading as="h3" size="lg" variant="hero">
							Hide your video?
						</Heading>
						<Text>People will no longer be able to watch the video.</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								onHideVideo()
								onClose()
							}}>
							Hide Video
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalHideVideo
