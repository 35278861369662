import { useState, useEffect } from 'react'
import { useEventContext } from '../contexts/EventProvider'
import { useMedia } from './useMedia'
import uniqBy from 'lodash/uniqBy'

const useContributors = () => {
	const { event } = useEventContext()
	const media = useMedia()

	const [uniqueContributors, setUniqueContributors] = useState([])

	useEffect(() => {
		const mediaWithContributor = media.filter((m) => m.contributorId != event.creatorId && m.contributorId != null)

		const result = uniqBy(mediaWithContributor, 'contributorId')
		if (!uniqueContributors.length) {
			setUniqueContributors(result)
		}
	}, [event, media])

	return uniqueContributors
}

export default useContributors
