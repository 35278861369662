import React, { useState, useEffect } from 'react'
import {
	Flex,
	Button,
	Box,
	Text,
	HStack,
	Image,
	Divider,
	VStack,
	Heading,
	useDisclosure,
	useMultiStyleConfig,
} from '@chakra-ui/react'
import Card from '../../../../atoms/Card'
import Toggle from '../../../../atoms/Toggle'
import useContributors from '../../../../../hooks/useContributors'
import { updateEvent } from '../../../../../../api/app/events'
import { useDispatch } from 'react-redux'
import useScrollToHash from '../../../../../hooks/useScrollToHash'
import { useLocation } from 'react-router-dom'
import ModalHideVideo from '../../../../organisms/ModalHideVideo'
const graphicPrivate = '/assets/images/graphics/navigation-cards/vidday-private-video.jpg'
const graphicPublic = '/assets/images/graphics/navigation-cards/vidday-video-public.jpg'

const VisibilityHeader = ({ isPrivate, handleTogglePrivate }) => {
	const hideVideoModal = useDisclosure()
	const styles = useMultiStyleConfig('NavCard')

	return (
		<>
			<HStack spacing="1rem" w="full">
				<Box as="figure" minH="80px" minW="80px" h="80px" w="80px" borderTopLeftRadius="xl" overflow="hidden">
					{isPrivate ? (
						<Image
							h="80px"
							w="80px"
							srcSet={`${graphicPrivate} 1x, ${graphicPrivate.replace('.jpg', '@2x.jpg')} 2x`}
							alt="Hidden"
						/>
					) : (
						// <Image src="/assets/images/icons/vidday-private-video.jpg" boxSize="50px" alt="Hidden" />
						<Image
							h="80px"
							w="80px"
							srcSet={`${graphicPublic} 1x, ${graphicPublic.replace('.jpg', '@2x.jpg')} 2x`}
							alt="Hidden"
						/>
						//<Image src="/assets/images/icons/smiley_stars.svg" boxSize="50px" alt="Visible" />
					)}
				</Box>

				<Box flexGrow="1">
					<Text sx={styles.title}>{isPrivate ? 'Private' : 'Shareable'}</Text>
					<Text sx={styles.description}>{isPrivate ? `Video is not shareable` : `Share the video link`}</Text>
				</Box>

				<Box justifyContent="flex-end" pr="1rem">
					<Toggle
						value={!isPrivate}
						onToggle={isPrivate ? handleTogglePrivate : hideVideoModal.onOpen}
						theme="visibility"
					/>
					{/* <Toggle
						input={{ value: !isPrivate }}
						onToggle={isPrivate ? handleTogglePrivate : hideVideoModal.onOpen}
						theme={'visibility'}
					/> */}
				</Box>
			</HStack>
			<ModalHideVideo
				isOpen={hideVideoModal.isOpen}
				onClose={hideVideoModal.onClose}
				onHideVideo={handleTogglePrivate}
			/>
		</>
	)
}

export const VisibilitySelector = ({ event, ...rest }) => {
	const dispatch = useDispatch()

	/** Retrieve unique contributors of the current event */
	const uniqueContributors = useContributors()

	/** Manage modals state */
	const notifyModal = useDisclosure()

	/** Save state locally for fast rendering */
	const [isPrivate, setIsPrivate] = useState(event.isPrivate)
	const [allowDownload, setAllowDownload] = useState(event.allowDownload)
	const [allowKeepsakes, setAllowKeepsakes] = useState(event.allowKeepsakes)
	// const [allowKeepsakePurchase, setAllowKeepsakePurchase] = useState(event.allowKeepsakePurchase)

	/** Deconstruct event props */
	const { id, uuid, contributorsNotified } = event
	const location = useLocation()

	useScrollToHash({ location })

	/**
	 * Handler to allow others to download the video
	 */
	const handleToggleDownload = () => {
		let eventData = { id, uuid, allowDownload: !allowDownload }
		dispatch(updateEvent(eventData, true))
		setAllowDownload(!allowDownload)
	}

	/**
	 * Handler to allow keepsakes for the video
	 */
	const handleToggleKeepsakes = () => {
		let eventData = { id, uuid, allowKeepsakes: !allowKeepsakes }
		dispatch(updateEvent(eventData, true))
		setAllowKeepsakes(!allowKeepsakes)
	}

	/**
	 * Handler to make the event private/public
	 */
	const handleTogglePrivate = () => {
		let eventData = { id, uuid, isPrivate: !isPrivate }
		dispatch(updateEvent(eventData, true))
		if (isPrivate && !contributorsNotified && uniqueContributors.length > 0) {
			notifyModal.onOpen()
		}
		setIsPrivate(!isPrivate)
	}

	/**
	 * When event change, update is private and download
	 */
	useEffect(() => {
		setIsPrivate(event.isPrivate)
		setAllowDownload(event.allowDownload)
	}, [event])

	return (
		<Card flexDirection="column" alignItems="center" {...rest}>
			<VisibilityHeader isPrivate={isPrivate} handleTogglePrivate={handleTogglePrivate} />

			{!isPrivate && (
				<>
					<Box w="full">
						<Divider />
						<Flex
							direction="row"
							spacing=".5rem"
							p=".875rem 1rem"
							alignItems="center"
							justify="space-between">
							<Text me="1rem" fontSize=".875rem">
								Let viewers download the video
							</Text>
							<Toggle
								value={allowDownload}
								transform="scale(.75)"
								onToggle={handleToggleDownload}
								theme="check"
							/>
						</Flex>
					</Box>
					<Box w="full">
						<Divider variant="dashed" />
						<Flex
							direction="row"
							spacing=".5rem"
							p=".875rem 1rem"
							alignItems="center"
							justify="space-between">
							<Text me="1rem" fontSize=".875rem">
								Let viewers purchase a keepsake
							</Text>
							<Toggle
								value={allowKeepsakes}
								transform="scale(.75)"
								onToggle={handleToggleKeepsakes}
								theme="check"
							/>
						</Flex>
					</Box>
				</>
			)}
		</Card>
	)
}
